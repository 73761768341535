// import * as mandrill from "mandrill-api/mandrill";

export default async function postContactForm(drupalUrl, data, type) {
  return new Promise(async function (resolve, reject) {
    // const creds = btoa(
    //   `${process.env.BASIC_REST_USERNAME}:${process.env.BASIC_REST_PASSWORD}`
    // );

    const url = `${drupalUrl}/contact_form/send?_format=json`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        // Authorization: `Basic ${creds}`,
      },
      body: JSON.stringify({
        type,
        data,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          let resp = await response.json();
          return resp;
        } else {
          console.error("response", response);
          reject(`${response.status} - ${response.statusText}`);
        }
      })
      .then((response) => {
        if (response && response.data === "OK") {
          resolve();
        } else {
          reject("Something went wrong");
        }
      })
      .catch(function (err) {
        reject(err.message);
      });
  });
}
